import React from 'react';
import { CurrencyType } from '~constants/currencyMap';
import RelevanceSlider from './components/RelevanceSlider';
import ObjectTile from '~components/ObjectTile';
import Tabs from '~components/Tabs';
import { ObjectType } from '~entities/Object';
import { favoritesContextData } from '~contextProviders/FavoritesContextProvider';

import styles from './styles.module.css';

type GroupType = {
  title: string;
  name: string;
  items: ObjectType[];
  emptyItemsMessage: string;
};

export type Props = {
  title: string;
  groups: GroupType[];
  currency: CurrencyType;
};

const Relevance = ({ title, groups, currency }: Props) => {
  const { hasFavorites, toggleFavorite } = favoritesContextData.useCtx();
  const labels = groups.map((item) => item.title);

  return (
    <div className='container'>
      <Tabs labels={labels} title={title}>
        {groups.map((group, i) => {
          if (!group.items.length) {
            return (
              group.emptyItemsMessage && (
                <div key={i} className={styles['slider__empty-message']}>
                  {group.emptyItemsMessage}
                </div>
              )
            );
          }

          const sliderContainerClasses = [];
          const slidesCount = group.items.length;

          if (slidesCount === 2) {
            sliderContainerClasses.push(styles['slider-container--two-items']);
          }

          if (slidesCount === 1) {
            sliderContainerClasses.push(styles['slider-container--one-item']);
          }

          return (
            <div className={sliderContainerClasses.join(' ')} key={group.name}>
              <RelevanceSlider slidesCount={slidesCount}>
                {group.items.map((item) => {
                  const favoritesProp = {
                    toggleFavorite: () => toggleFavorite(item.id_crm),
                    isFavorite: hasFavorites(item.id_crm),
                  };

                  return (
                    <div key={item.id_crm}>
                      <ObjectTile
                        objectData={item}
                        currency={currency}
                        favorites={favoritesProp}
                      />
                    </div>
                  );
                })}
              </RelevanceSlider>
            </div>
          );
        })}
      </Tabs>
    </div>
  );
};

export default Relevance;
