import React from 'react';
import round from 'lodash/round';
import LazyImage from '~components/LazyImage';
import { ObjectType } from '~entities/Object';
import FavoriteButton from '~components/FavoriteButton';
import { Location } from 'styled-icons/icomoon/Location';
import getFormattedPrice from '~utils/getFormattedPrice';
import { CurrencyType } from '~constants/currencyMap';
import styles from './styles.module.css';

type Props = {
  openLinkInNewTab?: boolean;
  className?: string;
  objectLinkHost?: string;
  objectData: Pick<
    ObjectType,
    | 'total_area'
    | 'type_listing'
    | 'url'
    | 'name'
    | 'img'
    | 'costm_d'
    | 'costm_r'
    | 'cost_d'
    | 'cost_r'
  >;
  currency?: CurrencyType;
  favorites?: {
    toggleFavorite?: () => void;
    isFavorite?: boolean;
  };
};

const ObjectTile = ({
  openLinkInNewTab = false,
  objectLinkHost = '',
  currency = 'ruble',
  className,
  favorites,
  objectData: {
    total_area,
    type_listing,
    url,
    name,
    img,
    costm_d,
    costm_r,
    cost_d,
    cost_r,
  },
}: Props) => {
  const objectUrl = `${objectLinkHost}/object/${url}`;
  const isRent = type_listing === 'rent';
  const linkTarget = openLinkInNewTab ? '_blank' : '_self';
  const isDollar = currency === 'dollar';

  const price = getFormattedPrice({
    price: isDollar ? costm_d : costm_r,
    currency,
    useLabelAsSuffix: true,
  });
  const priceFull = getFormattedPrice({
    price: isDollar ? cost_d : cost_r,
    currency,
    useLabelAsSuffix: true,
  });

  const classes = [styles['object-tile']];
  const priceClasses = [styles['object-tile__price']];
  priceClasses.push(styles[isRent ? 'object-tile__rent' : 'object-tile__sale']);

  if (className) classes.push(className);

  return (
    <div className={classes.join(' ')}>
      <div className={styles['object-tile__image']}>
        <div className={priceClasses.join(' ')}>
          {isRent ? 'Аренда' : 'Продажа'}
        </div>
        {favorites && (
          <div className={styles['object-tile__favorite']}>
            <FavoriteButton
              size={22}
              isActive={favorites.isFavorite}
              onClick={favorites.toggleFavorite}
            />
          </div>
        )}
        <a href={objectUrl} target={linkTarget}>
          <LazyImage src={img} alt={name} />
        </a>
      </div>
      <div className={styles['object-tile__info']}>
        <div className={styles['object-tile__desc']}>
          <div className={styles['object-tile__desc-item']}>
            <div className={styles['object-tile__desc-value']}>
              <b>{priceFull}</b>
            </div>
            <div className={styles['object-tile__desc-label']}>
              {isRent ? 'В месяц' : 'Полная стоимость'}
            </div>
          </div>
          <div className={styles['object-tile__desc-item']}>
            <div className={styles['object-tile__desc-value']}>
              <b>{price}</b>
            </div>
            <div className={styles['object-tile__desc-label']}>
              Цена за м²{isRent && '/год'}
            </div>
          </div>
          <div className={styles['object-tile__desc-item']}>
            <div className={styles['object-tile__desc-value']}>
              <b>{round(total_area)} м²</b>
            </div>
            <div className={styles['object-tile__desc-label']}>Площадь</div>
          </div>
          <div className={styles['object-tile__desc-item__location']}>
            <Location size={15} fill='#a9b7c0' />
            <span className={styles['object-tile__location-name']}>{name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObjectTile;
