import React from 'react';
import { hydrate } from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import Relevance from '~components/Relevance';
import { CurrencyContextProvider, currencyContextData } from '~contextProviders/CurrencyContextProvider';
import { FavoritesContextProvider } from '~contextProviders/FavoritesContextProvider';
import defaultState from "./default-state.json";

const { Context: CurrencyContext } = currencyContextData;

const RelevanceEntry = ({ config }) => {
  const data = Object.assign({}, defaultState, config);
  return (
    <CookiesProvider>
      <FavoritesContextProvider>
        <CurrencyContextProvider>
          <CurrencyContext.Consumer>
            {(contextValue) => {
              if (contextValue) {
                return (
                  <Relevance
                    currency={contextValue.currency}
                    {...data.relevance}
                  />
                );
              }
            }}
          </CurrencyContext.Consumer>
        </CurrencyContextProvider>
      </FavoritesContextProvider>
    </CookiesProvider>
  )
};

if (typeof window["RC_APPS"] !== "object") window["RC_APPS"] = {};

window["RC_APPS"]["relevance"] = (container, widgetProps) => {
  hydrate(RelevanceEntry(widgetProps), container);
};

if (module.hot) {
  module.hot.accept();
}
