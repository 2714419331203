import React from 'react';
import Slick from 'react-slick';
import 'slick-carousel/slick/slick.css';

const Slider = ({ children, ...sliderSettingsProps }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...sliderSettingsProps,
  };

  return <Slick {...settings}>{children}</Slick>;
};

export default Slider;
