import React from 'react';
import Slider from '~components/Slider';
import './slick-theme.css';

type Props = {
  slidesCount: number;
  children: React.ReactNode;
};

const RelevanceSlider = ({ slidesCount, children }: Props) => {
  const lgSlidesToShow = slidesCount < 3 ? slidesCount : 3;
  const mdSlidesToShow = slidesCount < 2 ? slidesCount : 2;

  const settings = {
    className: 'rslider',
    slidesToShow: lgSlidesToShow,
    infinite: true,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: mdSlidesToShow,
          slidesToScroll: mdSlidesToShow,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {React.Children.map(children, (child) => (
        <div className='rslider__slide'>{child}</div>
      ))}
    </Slider>
  );
};

export default RelevanceSlider;
