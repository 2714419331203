import React, { useState } from 'react';
import { withCookies, ReactCookieProps } from 'react-cookie';
import { CurrencyType } from '~constants/currencyMap';
import createCtx from '~utils/createCtx';

type ContextValue = {
  currency: CurrencyType;
  setCurrency: (currency: CurrencyType) => void;
};

export type Props = ReactCookieProps & {
  defaultCurrency?: CurrencyType;
  children: React.ReactNode;
};

export const currencyContextData = createCtx<ContextValue>();

export const CurrencyContextProvider = withCookies(
  ({ cookies, defaultCurrency = 'ruble', children }: Props) => {
    const { Context } = currencyContextData;
    const [currency, setCurrencyState] = useState<CurrencyType>(() => {
      const cookieCurrency = cookies && cookies.get<CurrencyType>('currency');
      return cookieCurrency || defaultCurrency;
    });

    const setCurrency = (currency: CurrencyType) => {
      setCurrencyState(currency);

      if (cookies) {
        cookies.set('currency', currency, {
          path: '/',
          maxAge: +Date.now() + 3600 * 12 * 365,
        });
      }
    };

    return (
      <Context.Provider
        value={{
          currency,
          setCurrency,
        }}
      >
        {children}
      </Context.Provider>
    );
  },
);
