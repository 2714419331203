import React from 'react';
import { withCookies, ReactCookieProps } from 'react-cookie';
import { ObjectType } from '~entities/Object';
import createCtx from '~utils/createCtx';

type ObjectIdType = ObjectType['id_crm'];

type State = {
  favoriteIds: ObjectIdType[];
};

type Props = ReactCookieProps & {
  children: React.ReactNode;
};

type FavoritesContext = {
  hasFavorites: (id: ObjectIdType) => boolean;
  toggleFavorite: (id: ObjectIdType) => void;
  favoriteIds: ObjectIdType[];
};

export const favoritesContextData = createCtx<FavoritesContext>();

class Favorites extends React.Component<Props, State> {
  static dispathEventUdpateFavorite() {
    const ev = new Event('UPDATE_FAVORITE');
    window.dispatchEvent(ev);
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      favoriteIds: [],
    };
  }

  componentDidMount() {
    const favoriteIds = this.get();

    if (favoriteIds.length) this.setState({ favoriteIds });
  }

  get = () => {
    const { cookies } = this.props;
    if (!cookies) {
      return [];
    }
    const favoriteIds = cookies.get<number[] | undefined>('favorite');

    return Array.isArray(favoriteIds) ? favoriteIds : [];
  };

  set = (favoriteIds: ObjectIdType[]) => {
    const { cookies } = this.props;

    this.setState({ favoriteIds }, () => {
      if (cookies) {
        cookies.set('favorite', favoriteIds, {
          path: '/',
          maxAge: +Date.now() + 3600 * 12 * 365,
        });
      }

      Favorites.dispathEventUdpateFavorite();
    });
  };

  toggleFavorite = (id: ObjectIdType): void => {
    const { favoriteIds } = this.state;

    const index = favoriteIds.indexOf(id);
    index !== -1 ? favoriteIds.splice(index, 1) : favoriteIds.push(id);

    this.set(favoriteIds);
  };

  hasFavorites = (id: ObjectIdType): boolean =>
    this.state.favoriteIds.includes(id);

  render() {
    const { children } = this.props;
    const { Context } = favoritesContextData;

    const contextValue = {
      hasFavorites: this.hasFavorites,
      toggleFavorite: this.toggleFavorite,
      favoriteIds: this.state.favoriteIds,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
  }
}

export const FavoritesContextProvider = withCookies(Favorites);
