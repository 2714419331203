import wNumb from 'wnumb';
import round from 'lodash/round';
import currencyMap, { CurrencyType } from '~constants/currencyMap';

type Args = {
  price: number;
  currency?: CurrencyType;
  hasSuffix?: boolean;
  useLabelAsSuffix?: boolean;
};

const MILLION = 1000000;
const BILLION = 1000000000;

export default function getFormattedPrice({
  price,
  currency = 'ruble',
  hasSuffix = true,
  useLabelAsSuffix,
}: Args): string {
  const map = currencyMap[currency];
  const mapCopy = { ...map };

  let suffix;

  if (hasSuffix) {
    suffix = useLabelAsSuffix ? ` ${map.label}` : map.suffix;
  } else {
    suffix = '';
  }

  if (price >= BILLION) {
    price = round(price / BILLION, 2);
    mapCopy.suffix = `${map.billions}${suffix}`;
  } else if (price >= MILLION) {
    price = round(price / MILLION, 2);
    mapCopy.suffix = `${map.millions}${suffix}`;
  } else {
    price = round(price);
    mapCopy.suffix = suffix;
  }

  return wNumb(mapCopy).to(price);
}
