import React from 'react';
import { StarEmpty } from 'styled-icons/icomoon/StarEmpty';
import { StarFull } from 'styled-icons/icomoon/StarFull';
import { IconWrapper } from './styledComponents';

type Props = {
  size: number;
  isActive?: boolean;
  isActiveGrey?: boolean;
  className?: string;
  onClick?: () => void;
};

const FavoriteButton = ({
  className,
  size,
  isActiveGrey,
  isActive,
  onClick,
}: Props) => {
  const activeFill = isActiveGrey ? '#cdcdcc' : '#7db828';

  return (
    <IconWrapper className={className} onClick={onClick}>
      {isActive ? (
        <StarFull
          size={size}
          fill={isActive && activeFill}
          stroke='whitesmoke'
        />
      ) : (
        <StarEmpty size={size} fill='#e3e3e2' />
      )}
    </IconWrapper>
  );
};

export default FavoriteButton;
