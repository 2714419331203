export type CurrencyType = 'ruble' | 'dollar';

type CurrencySettings = {
  id: number;
  thousand: string;
  suffix: string;
  prefix?: string;
  label: string;
  millions: string;
  billions: string;
};

const currencyMap: Record<CurrencyType, CurrencySettings> = {
  ruble: {
    id: 1,
    thousand: ' ',
    suffix: ' руб.',
    label: 'Р',
    millions: ' млн.',
    billions: ' млрд.',
  },
  dollar: {
    id: 2,
    thousand: ' ',
    suffix: '',
    prefix: '$ ',
    label: '$',
    millions: 'M',
    billions: 'B',
  },
};

export default currencyMap;
