import React, { useState } from 'react';
import styles from './styles.module.css';

type Props = {
  title: string;
  labels: string[];
  children: React.ReactNode;
  activeIndex?: number;
};

const Tabs = (props: Props) => {
  const { title, children, labels } = props;

  const [activeIndex, setActiveIndex] = useState(props.activeIndex || 0);

  return (
    <div className={styles['tabs']}>
      <div className='container'>
        <div className={styles['tabs__head']}>
          {title && <div className={styles['tabs__title']}>{title}</div>}
          <div className={styles['tabs__items']}>
            {labels.map((item, i) => {
              const clases = [styles['tabs__item']];
              if (i === activeIndex) clases.push(styles['tabs__item_active']);

              return (
                <div
                  className={clases.join(' ')}
                  key={i}
                  onClick={() => setActiveIndex(i)}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles['tabs__body']}>
        {Array.isArray(children) ? (
          children.map((item, i) => {
            return i === activeIndex ? (
              <div className={styles['tabs__body-item']} key={`tabs-body-${i}`}>
                {item}
              </div>
            ) : null;
          })
        ) : (
          <div className={styles['tabs__body-item']}>{children}</div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
