import React from 'react';

//create context with this function so you can init it with no default value without typescript complaining about it
const createCtx = <A>() => {
  const Context = React.createContext<A | undefined>(undefined);

  function useCtx() {
    const c = React.useContext(Context);
    if (!c) throw new Error('useCtx must be inside a Provider with a value');
    return c;
  }

  return {
    useCtx,
    Context,
  };
};

export default createCtx;
