import styled from 'styled-components';

export const IconWrapper = styled.div`
  display: inline-block;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;
