import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styles from './styles.module.css';

type Props = { src: string; alt?: string };

const LazyImage = ({ src, alt }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);

  const onVisibilityChange = (isVisible: boolean) => setIsVisible(isVisible);

  const onLoad = () => {
    setIsLoading(false);
    setHasLoaded(true);
    setHasFailed(false);
  };

  const onError = () => {
    setIsLoading(false);
    setHasLoaded(false);
    setHasFailed(true);
  };

  const classes = [];
  if (isLoading) classes.push(styles['lazy-image__loading']);
  if (hasLoaded) classes.push(styles['lazy-image__loaded']);
  if (hasFailed) classes.push(styles['lazy-image__failed']);

  const showImage = isVisible && !hasFailed && src;

  return (
    <VisibilitySensor
      partialVisibility
      active={!isVisible}
      onChange={onVisibilityChange}
    >
      <div className={styles['lazy-image']}>
        <div className={classes.join(' ')}>
          {showImage && (
            <img src={src} alt={alt} onLoad={onLoad} onError={onError} />
          )}
          {hasFailed && <span>Нет фото</span>}
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default LazyImage;
